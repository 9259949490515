import * as i18n from 'vue-i18n'

const locale = document.querySelector('html').getAttribute('lang')
let app

export const createI18n = (messages) => app = i18n.createI18n({
    legacy: false,
    locale,
    messages,
    warnHtmlMessage: false,
  })

export const useI18n = (shorthand = null) => {
  const { t, tm } = i18n.useI18n()

  const changeLocale = (l, callback) => {
    app.global.locale.value = l
    const result = callback()
    app.global.locale.value = locale
    return result
  }

  const lt = (l, key, args) => changeLocale(l, () => t(key, args))
  const ltm = (l, key, args) => changeLocale(l, () => tm(key, args))

  return {
    isEn: locale == 'en',
    lt,
    ltm,
    mt: (model, key, args) => t(`activerecord.attributes.${model}.${key}`, args),
    t,
    tm,
    vt: (key, args) => shorthand ? t(`${shorthand}.${key}`, args) : t(key, args)
  }
}
